import * as ReactDOM from 'react-dom/client';
import App from './app/app';
import DevAppletTool from './app/dev-applet-tool';
import { Payload } from '@web-contents/shared';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

let _callbackFunction: any;

const callbackFunction = (e: any) => {
  // call the callback function with the message
  _callbackFunction(e.detail.messageOutput);
};

const webActivityContents = {
  setMessage: (message: string) => {
    // Create a custom event
    const messageEvent = new CustomEvent('messageUpdatedInput', {
      detail: {
        message,
      },
    });

    // Dispatch the custom event
    window.dispatchEvent(messageEvent);
  },
  // receiveMessage with callback function as parameter
  receiveMessage: (callback: (message: string) => void) => {
    // listen for the custom event messageUpdatedOutput
    _callbackFunction = callback;
    window.removeEventListener('messageUpdatedOutput', callbackFunction);
    window.addEventListener('messageUpdatedOutput', callbackFunction);
  },
  init: (payload: Payload, rootId?: string) => {
    const root = ReactDOM.createRoot(
      document.getElementById(rootId || 'root-webcontents') as HTMLElement,
    );
    root.render(<App payload={payload} />);
  },
  devAppletTool: () => {
    const root = ReactDOM.createRoot(
      document.getElementById('root-webcontents') as HTMLElement,
    );
    root.render(
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<DevAppletTool />} />
        </Routes>
      </BrowserRouter>,
    );
  },
};

declare global {
  interface Window {
    webActivityContents: any;
  }
}

window.webActivityContents = webActivityContents;
