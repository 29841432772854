import styled from 'styled-components';
import { ExitConnectionButton } from '../exit-connection-button';
import WifiAnimation from '../wifi-animation/wifi-animation';
import { t } from 'i18next';
import LoadingConnectionAnimation from '../loading-connection-animation/loading-connection-animation';
import { useEffect, useState } from 'react';

export type ConnectionStatus = 'loading' | 'disconnected';

type Props = {
  message: string;
  status: ConnectionStatus;
  handleOnClickExitButton: () => void;
  handleCloseMessage?: () => void;
  handleSendEvent?: (status: ConnectionStatus) => void;
};

const BackgroundBlocker = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  pointer-events: all;
  z-index: 2;
`;

const ConnectionWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  padding: 16px;
  position: absolute;
  z-index: 1000;
  width: 100%;
  box-sizing: border-box;
  left: 40px;
  right: 40px;
  bottom: 16px;
  max-width: calc(100% - 80px);
`;

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const Message = styled.p`
  color: white;
  font-family: ${({ theme }) => theme.typography.regular};
  font-size: ${({ theme }) => theme.typography.typeScale.medium};
  font-weight: ${({ theme }) => theme.typography.weight.regular};
`;
const AnimationWrapper = styled.div`
  position: relative;
`;
const CountDownWrapper = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: ${({ theme }) => theme.typography.regular};
  font-size: ${({ theme }) => theme.typography.typeScale.medium};
  font-weight: ${({ theme }) => theme.typography.weight.regular};
`;

const ButtonWrapper = styled.div<{ isVisible: boolean }>`
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`;

const statusIconAnimation = {
  loading: (loadingConnectionCountdown: number) => (
    <AnimationWrapper>
      <LoadingConnectionAnimation />
      <CountDownWrapper>{loadingConnectionCountdown}</CountDownWrapper>
    </AnimationWrapper>
  ),
  disconnected: () => <WifiAnimation />,
};

export function ConnectionMessage({
  message,
  status,
  handleOnClickExitButton,
  handleCloseMessage,
  handleSendEvent,
}: Props) {
  const [loadingConnectionCountdown, setLoadingConnectionCountdown] =
    useState(20);

  useEffect(() => {
    if (loadingConnectionCountdown === 0) {
      handleCloseMessage?.();
    }

    const timer = setInterval(() => {
      setLoadingConnectionCountdown((prevCount) => prevCount - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [loadingConnectionCountdown]);

  useEffect(() => {
    handleSendEvent?.(status);
  }, [status]);

  const showButton =
    status === 'disconnected' || loadingConnectionCountdown <= 10;

  return (
    <>
      <BackgroundBlocker />
      <ConnectionWrapper>
        <MessageWrapper>
          {statusIconAnimation[status](loadingConnectionCountdown)}
          <Message>{message}</Message>
        </MessageWrapper>
        <ButtonWrapper isVisible={showButton}>
          <ExitConnectionButton onClick={handleOnClickExitButton}>
            {t('secondaryPractice.waitingConnection.button')}
          </ExitConnectionButton>
        </ButtonWrapper>
      </ConnectionWrapper>
    </>
  );
}
