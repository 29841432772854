export type breakpointsType = {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
  xxl: string;
};

export const breakpoints: breakpointsType = {
  xs: '320px',
  sm: '600px',
  md: '960px',
  lg: '1280px',
  xl: '1440px',
  xxl: '1920px',
};
