import { OptionsSelector } from '@web-contents/types';

import { ApiResponse, AllLanguages } from '@web-contents/types';
import axios from 'axios';

const VITE_APOLLO = import.meta.env.VITE_APOLLO;

export const apolloFetch = async ({
  graphQl,
}: {
  graphQl: string;
}): Promise<ApiResponse> => {
  const response = await axios({
    url: VITE_APOLLO,
    method: 'POST',
    data: JSON.parse(graphQl),
  });
  return response.data;
};

export function transformKeysToArray(languages: AllLanguages): string[] {
  return Object.keys(languages);
}

export const selectStageObject: OptionsSelector = {
  PRIMARIA: 'Primary',
  SECUNDARIA: 'Secondary',
};
