import styled from 'styled-components';

/* eslint-disable-next-line */
export interface ButtonProps {}

const StyledButton = styled.button`
  width: 90px;
  height: 30px;
`;

export function Button(props: ButtonProps) {
  return <StyledButton>I'm a button</StyledButton>;
}

export default Button;
