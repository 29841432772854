import styled, { css } from 'styled-components';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}

const StyledButton = styled.button<ButtonProps>`
  border: none;
  cursor: pointer;
  border-radius: 4px;
  padding: 8px 16px;
  font-family: ${({ theme }) => theme.typography.regular};
  font-size: ${({ theme }) => theme.typography.typeScale.medium};
  font-weight: ${({ theme }) => theme.typography.weight.regular};
  background-color: white;
  color: #222f2d;

  &:hover {
    background-color: #cccccc;
    color: #222f2d;
  }

  &:active {
    background-color: #b8b8b8;
    color: #222f2d;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #cccccc;
      color: #adadad;
      cursor: default;

      &:hover {
        background-color: #cccccc;
        color: #adadad;
      }
    `}
`;

export function ExitConnectionButton({
  children,
  onClick,
  disabled,
  ...rest
}: ButtonProps) {
  return (
    <StyledButton onClick={onClick} disabled={disabled} {...rest}>
      {children}
    </StyledButton>
  );
}

export default ExitConnectionButton;
