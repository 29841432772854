import { createGlobalStyle } from 'styled-components';

const robotoBold = 'https://files.innovamat.com/fonts/roboto/roboto-bold.woff2';
const robotoItalic =
  'https://files.innovamat.com/fonts/roboto/roboto-italic.woff2';
const robotoLight =
  'https://files.innovamat.com/fonts/roboto/roboto-light.woff2';
const robotoRegular =
  'https://files.innovamat.com/fonts/roboto/roboto-regular.woff2';
const robotoMedium =
  'https://files.innovamat.com/fonts/roboto/roboto-medium.woff2';
const cmr10 = 'https://files.innovamat.com/fonts/cmr10/cmr10.woff2';

export const FontsStyle = createGlobalStyle`
 @font-face {
    font-family: 'Roboto';
    src: url(${robotoBold}) format('woff2');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Roboto';
    src: url(${robotoItalic}) format('woff2');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: 'Roboto';
    src: url(${robotoLight}) format('woff2');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Roboto';
    src: url(${robotoRegular}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Roboto';
    src: url(${robotoMedium}) format('woff2');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Roboto Medium';
    src: url(${robotoMedium}) format('woff2');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Cmr10';
    src: url(${cmr10}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }
`;
