export type spacesType = {
  big: string;
  xxxlarge: string;
  xxlarge: string;
  xlarge: string;
  large: string;
  semilarge: string;
  medium: string;
  normal: string;
  semismall: string;
  small: string;
  xsmall: string;
  xxsmall: string;
  xxxsmall: string;
  micro: string;
};

export const spaces: spacesType = {
  big: '3rem', //48px
  xxxlarge: '2.75rem', //44px
  xxlarge: '2.5rem', //40px
  xlarge: '2.25rem', //36px
  large: '2rem', //32px
  semilarge: '1.625rem', //26px
  medium: '1.25rem', //20px
  normal: '1rem', //16px
  semismall: '0.875rem', //14px
  small: '0.75rem', //12px
  xsmall: '0.5rem', //8px
  xxsmall: '0.38rem', //4px
  xxxsmall: '0.25rem', //4px
  micro: '0.125rem', // 2px
};
