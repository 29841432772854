import React, { useEffect, useState } from 'react';
import Editor from '@monaco-editor/react';
import App from './app';
import styled from 'styled-components';
import {
  BuildData,
  AllLanguages,
  defaultPayload as initialDefaultPayload,
  Payload,
} from '@web-contents/shared';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  selectStageObject,
  apolloFetch,
  transformKeysToArray,
} from './locales/utils';

import {
  BaseInputStyles,
  InterfaceSelect,
} from './components/selector/selector';

import { ButtonInterfaceWrapper } from './components/button/button';

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 90px;
  text-align: center;
`;

const StyledForm = styled.form`
  font-family: Arial, Helvetica, sans-serif;
`;

const EditorWrapper = styled.div`
  display: flex;
  gap: 30px;
  margin: 10px;
`;

const Button = styled.button`
  margin: 10px 0;
  padding: 5px 12px;
  background-color: rgb(55, 205, 177);
  border-radius: 5px;
  border: none;
`;

const InfoBar = styled.div`
  background-color: rgb(55, 205, 177);
  padding: 5px;
  display: flex;
  flex-direction: row;
`;

const AppletSelected = styled.div`
  margin-left: auto;
  a {
    color: #fff;
  }
`;

const StyledLink = styled.a`
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  margin-right: 10px;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const Label = styled.label`
  margin-right: 10px;
  font-size: 22px;
  min-width: 100px;
  text-align: right;
  margin-bottom: 13px;
  line-height: 38px;
`;

const InterfaceRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const ButtonInterface = styled.button`
  margin: 10px 0;
  padding: 5px 12px;
  background-color: rgb(55, 205, 177);
  border-radius: 5px;
  border: none;
  padding: 15px;
  font-size: 18px;
  width: 48%;
`;

const InterfaceWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
`;

const InterfaceWrapperRows = styled.div`
  width: 350px;
`;

const InterfaceInput = styled.input`
  ${BaseInputStyles}
`;

const VITE_API_SOLVER = import.meta.env.VITE_API_SOLVER;
const VITE_PLATFORM = import.meta.env.VITE_PLATFORM;

const defaultPayload: Payload = {
  ...initialDefaultPayload,

  userParameters: {
    ...initialDefaultPayload.userParameters,
    platformUrl:
      typeof VITE_PLATFORM === 'undefined'
        ? 'https://innovamat-platform.pre.innovamat.cloud'
        : VITE_PLATFORM,
    solverUrl:
      typeof VITE_API_SOLVER === 'undefined'
        ? 'https://solver.pre.innovamat.cloud'
        : VITE_API_SOLVER,
  },
};

const DevAppletTool = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState('');
  const [isSubmitted, setSubmit] = useState(
    initialDefaultPayload.activityParameters.buildlog,
  );
  const [payload, setPayload] = useState<Payload>(defaultPayload);
  const [hasErrors, setCodeErrors] = useState(false);
  const [buildLogUrl, setBuildLogUrl] = useState<string>();
  const [selectedRegion, setSelectedRegion] = useState('ES');
  const [selectedLanguage, setSelectedLanguage] = useState('es');
  const [isInterface, setIsInterface] = useState<boolean>(
    selectedOption === 'unity',
  );
  const [allRegions, setAllRegions] = useState<string[]>(['ES']);
  const [allLanguages, setAllLanguages] = useState<AllLanguages>({
    ES: ['es'],
  });

  useEffect(() => {
    const graphQl = `
   {
     "query": "{ allLanguages }"
   }
 `;
    const data = apolloFetch({ graphQl });

    data
      .then(function (value) {
        const allLanguages = value.data.allLanguages;
        setAllRegions(transformKeysToArray(allLanguages));
        setAllLanguages(allLanguages);
      })
      .catch(function (error) {
        console.error('An error occurred:', error);
      });
  }, []);

  const devParameters = {
    isDevelopment: true,
    buildLogUrl,
    actions: {
      setBuildLog: (data: BuildData) => setBuildLog(data),
    },
  };

  const location = useLocation();

  const {
    activityParameters: { type },
  } = payload;

  useEffect(() => {
    setSelectedOption(type);
  }, [type]);

  useEffect(() => {
    const getParams = (params: URLSearchParams) => {
      return {
        type: params.get('activityParameters_type') || '',
        id: params.get('activityParameters_id') || '',
        sceneName: params.get('activityParameters_sceneName') || '',
        pack: params.get('activityParameters_pack') || '',
        minStatements: Number(params.get('activityParameters_minStatements')),
      };
    };

    const params = new URLSearchParams(location.search);
    const activityParameters = getParams(params);
    if (activityParameters.type) {
      setSelectedOption(activityParameters.type);
      setPayload((prevPayload) => ({
        ...prevPayload,
        activityParameters: {
          ...prevPayload.activityParameters,
          ...activityParameters,
        },
      }));
    }
  }, [location.search]);

  const handleOptionChange = (event: { target: { value: string } }) => {
    setSelectedOption(event.target.value);
    setPayload({
      ...payload,
      activityParameters: {
        ...payload.activityParameters,
        type: event.target.value,
      },
    });
  };

  function setBuildLog(bd: BuildData) {
    const { buildPath, buildDomain } = bd;
    const pathUrl = `${buildDomain}/${buildPath}Build/BuildLog.html`;
    setBuildLogUrl(pathUrl);
  }

  function handleEditorValidation(markers: any[]) {
    setCodeErrors(!!markers.length);
  }

  function handlePayloadChange(value: any, event: any) {
    setPayload(JSON.parse(value));
  }

  const removeQueryParams = () => {
    const url = new URL(window.location.href);
    url.search = '';
    window.history.pushState({}, document.title, url.toString());
  };

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setSubmit(true);

    if (type === 'unity') {
      const { stage } = payload.userParameters;
      const { sceneName, pack } = payload.activityParameters;
      navigate(`/unity/${stage}/${sceneName}/${pack}`);
    }
  };
  const handleChangeInterface = (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => {
    const { id, value } = event.target;

    const handlers: Record<string, () => void> = {
      selectStage: () =>
        setPayload({
          ...payload,
          userParameters: {
            ...payload.userParameters,
            stage: value,
          },
        }),
      inputScene: () =>
        setPayload({
          ...payload,
          activityParameters: {
            ...payload.activityParameters,
            sceneName: value,
          },
        }),
      inputPack: () =>
        setPayload({
          ...payload,
          activityParameters: {
            ...payload.activityParameters,
            pack: value,
          },
        }),
      selectRegion: () => {
        setSelectedRegion(value);
        setSelectedLanguage(allLanguages[value][0]);
      },
      selectLanguage: () => setSelectedLanguage(value),
    };

    handlers[id]?.();
  };

  useEffect(() => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      userParameters: {
        ...prevPayload.userParameters,
        language: selectedLanguage,
      },
    }));
  }, [selectedLanguage]);

  useEffect(() => {
    setIsInterface(type === 'unity');
  }, [type]);

  const handleButtonClickSet = () => {
    setIsInterface(false);
  };
  const handleButtonClickBackToInterface = () => {
    setSelectedOption('unity');
    setPayload({
      ...payload,
      activityParameters: {
        ...payload.activityParameters,
        type: 'unity',
      },
    });
    setIsInterface(true); // setPayload above does not trigger useEffect that sets isInterface to true in case type already is 'unity'
  };

  const handleBackClick = () => {
    setSubmit(false);
    setPayload({
      ...payload,
      activityParameters: {
        ...payload.activityParameters,
        buildlog: false,
      },
    });
    removeQueryParams();
  };

  if (!isSubmitted) {
    if (isInterface) {
      return (
        <StyledForm onSubmit={handleSubmit}>
          <InterfaceWrapper>
            <Title>Applet Dev Tool</Title>

            <InterfaceWrapperRows>
              <InterfaceRow>
                <Label>Stage:</Label>
                <InterfaceSelect
                  id="selectStage"
                  value={payload.userParameters.stage}
                  onChange={handleChangeInterface}
                >
                  {Object.keys(selectStageObject).map((key) => (
                    <option key={key} value={key}>
                      {selectStageObject[key]}
                    </option>
                  ))}
                </InterfaceSelect>
              </InterfaceRow>

              <InterfaceRow>
                <Label>Scene:</Label>
                <InterfaceInput
                  id="inputScene"
                  type="text"
                  placeholder="Enter scene here"
                  value={
                    payload.activityParameters.sceneName === 'undefined'
                      ? ''
                      : payload.activityParameters.sceneName
                  }
                  onChange={handleChangeInterface}
                />
              </InterfaceRow>

              <InterfaceRow>
                <Label>Pack:</Label>
                <InterfaceInput
                  id="inputPack"
                  type="text"
                  placeholder="Enter pack here"
                  value={
                    payload.activityParameters.pack === '0'
                      ? ''
                      : payload.activityParameters.pack
                  }
                  onChange={handleChangeInterface}
                />
              </InterfaceRow>

              <InterfaceRow>
                <Label>Region:</Label>
                <InterfaceSelect
                  id="selectRegion"
                  value={selectedRegion}
                  onChange={handleChangeInterface}
                >
                  {allRegions.map((region) => (
                    <option key={region} value={region}>
                      {region}
                    </option>
                  ))}
                </InterfaceSelect>
              </InterfaceRow>

              <InterfaceRow>
                <Label>Language:</Label>
                <InterfaceSelect
                  id="selectLanguage"
                  value={selectedLanguage}
                  onChange={handleChangeInterface}
                >
                  {allLanguages[selectedRegion].map((lang) => (
                    <option key={lang} value={lang}>
                      {lang}
                    </option>
                  ))}
                </InterfaceSelect>
              </InterfaceRow>

              <ButtonInterfaceWrapper>
                <ButtonInterface type="submit" disabled={hasErrors}>
                  Run Applet
                </ButtonInterface>
                <ButtonInterface type="button" onClick={handleButtonClickSet}>
                  Set Payload
                </ButtonInterface>
              </ButtonInterfaceWrapper>
            </InterfaceWrapperRows>
          </InterfaceWrapper>
        </StyledForm>
      );
    } else {
      return (
        <StyledForm onSubmit={handleSubmit}>
          <h1>Applet Dev tool</h1>
          <EditorWrapper>
            <label>
              <input
                type="radio"
                value="web"
                checked={selectedOption === 'web'}
                onChange={handleOptionChange}
              />
              Web
            </label>
            <label>
              <input
                type="radio"
                value="unity"
                checked={selectedOption === 'unity'}
                onChange={handleOptionChange}
              />
              Unity
            </label>
          </EditorWrapper>
          <div>
            <Editor
              height="80vh"
              defaultLanguage="json"
              theme="vs-dark"
              value={JSON.stringify(payload, null, 2)}
              onChange={handlePayloadChange}
              onValidate={handleEditorValidation}
            />
          </div>
          <div>
            <Button
              type="submit"
              disabled={hasErrors}
              style={{ marginRight: '10px' }}
            >
              Run Applet
            </Button>
            <Button
              type="button"
              disabled={hasErrors}
              onClick={handleButtonClickBackToInterface}
            >
              Unity Interface
            </Button>
          </div>
        </StyledForm>
      );
    }
  } else {
    return (
      <>
        <InfoBar>
          <StyledLink onClick={handleBackClick}>← Back</StyledLink>

          <b> | Applet Dev Tool</b>
          <AppletSelected>
            {buildLogUrl && (
              <>
                <a href={buildLogUrl} target="_blank" rel="noreferrer">
                  Build Log
                </a>
                {' | '}
              </>
            )}
            Running Type: <b>{selectedOption}</b>
            {' | '}
            Applet:{' '}
            {selectedOption === 'unity' && (
              <b>
                {payload.activityParameters.sceneName} /{' '}
                {payload.activityParameters.pack}
              </b>
            )}
            {selectedOption === 'web' && <b>{payload.activityParameters.id}</b>}
          </AppletSelected>
        </InfoBar>
        <App payload={{ ...payload, devParameters }} />
      </>
    );
  }
};

export default DevAppletTool;
