import { useQuery } from 'react-query';
import { useMessageContext } from '../providers';
import { apiErrorHandling } from '../utils/api-error-handling';
import { axiosInstance } from '../utils/axios-instance';
// import { Activity } from '../utils/types';

export const getWebActivities = async (
  id: string,
  apiUrl: string,
  minStatements: number,
) => {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${apiUrl}/algebra/activities/${id}?min_statements=${minStatements}`,
    });
    return response.data;
  } catch (error) {
    apiErrorHandling(error, { callOrigin: 'getWebActivities', activityId: id });
  }
};

type Props = {
  id: string;
  type: string | undefined;
  apiUrl: string;
  minStatements: number;
};

export default function useGetActivities({
  id,
  type,
  apiUrl,
  minStatements,
}: Props) {
  const { updateMessageOutput } = useMessageContext();
  const query = useQuery(
    ['webActivities', id],
    () => {
      try {
        return getWebActivities(id, apiUrl, minStatements);
      } catch (error: any) {
        updateMessageOutput({
          eventId: 'Error',
          arguments: {
            activityId: id,
            error: error.response?.data,
          },
        });
        throw new Error(
          error.response?.data?.detail || 'Error loading activity',
        );
      }
    },
    {
      enabled: type !== 'unity' && !!id,
      retry: 3,
    },
  );
  return query;
}
