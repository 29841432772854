import {
  ActivityParameters,
  ActivityState,
  Payload,
  RegionParameters,
  RequestState,
  UserParameters,
  DevParameters,
} from './types';

const extractPathSegments = (url: string): string[] => {
  const urlObj = new URL(url);
  const path = urlObj.pathname;
  const segments: string[] = path
    .split('/')
    .filter((segment) => segment.length > 0);
  return segments;
};

const getBuildLogValue = (url: string): boolean => {
  const urlObj = new URL(url);
  return urlObj.searchParams.get('buildLog') === 'true';
};

const segments = extractPathSegments(window.location.href);
const buildlog = getBuildLogValue(window.location.href);

export const defaultActivityParameters: ActivityParameters = {
  id: '0a629f8b-9d9e-458a-a5fb-75e3de6831c5',
  type: segments.length > 0 ? segments[0] : 'undefined',
  codename: 'undefined',
  description: 'undefined',
  sceneName: segments.length > 2 ? segments[2] : 'undefined',
  pack: segments.length > 3 ? segments[3] : '0',
  minDuration: 60,
  maxDuration: 120,
  minStatements: 5,
  buildlog,
};

export const defaultUserParameters: UserParameters = {
  platformUrl: 'https://innovamat-platform.pre.innovamat.cloud',
  solverUrl: 'https://solver.pre.innovamat.cloud',
  Tokens: {
    AccessToken: '',
    RefreshToken: '',
    IdToken: '',
    clientID: 'innovamat_classroom_manager',
  },
  language: 'es',
  userToken: '',
  userId: '1',
  stage: segments.length > 1 ? segments[1] : 'PRIMARIA',
};

export const defaultRegionParameters: RegionParameters = {
  clock: '24h',
  currency: 'EUR',
  decimalSeparator: ',',
  metricSystem: 'international',
  shortDivisionSign: ':',
  shortMultiplicationSignCollection: {
    primary: '×',
    secondary: '⋅',
  },
  temperature: 'C',
  thousandSeparator: ' ',
  verticalDivisionAlgorithm: 'international',
  verticalMultiplicationPosition: 'left',
  verticalSubtractionPosition: 'left',
  verticalSumPosition: 'left',
  decimalSeparatorAudio: 'S4665',
  verticalOperationsParameters: {
    verticalDivisionAlgorithm: 'international',
    verticalMultiplicationPosition: 'left',
    verticalSubtractionPosition: 'left',
    verticalSumPosition: 'left',
  },
  shortMultiplicationSign: '×',
};

export const defaultDevParameters: DevParameters = {
  actions: {
    nothing: () => {},
  },
  isDevelopment: true,
  buildLogUrl: undefined,
};

export const defaultPayload: Payload = {
  activityParameters: defaultActivityParameters,
  regionParameters: defaultRegionParameters,
  userParameters: defaultUserParameters,
  solverUrl: 'https://solver.pre.innovamat.cloud',
};

export const defaultActivityState: ActivityState = {
  totalStatements: 0,
  title: 'Default Title',
};

export const defaultRequestState: RequestState = {
  slowRequest: false,
};
