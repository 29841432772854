import React, { createContext, useState, useContext } from 'react';
import { MessageType } from '../utils/types';

interface MessageContextProps {
  messageInput: string;
  updateMessageInput: (newMessage: string) => void;
  messageOutput: MessageType | undefined;
  updateMessageOutput: (newMessage: MessageType) => void;
}

const MessageContext = createContext<MessageContextProps | undefined>(
  undefined
);

const MessageProvider: React.FC<React.PropsWithChildren<object>> = ({
  children,
}) => {
  const [messageInput, setMessageInput] = useState('');
  const [messageOutput, setMessageOutput] = useState<MessageType>();

  const updateMessageInput = (newMessage: string) => {
    setMessageInput(newMessage);
  };

  const updateMessageOutput = (newMessage: MessageType) => {
    setMessageOutput(newMessage);
  };

  const contextValue: MessageContextProps = {
    messageInput,
    updateMessageInput,
    messageOutput,
    updateMessageOutput,
  };

  return (
    <MessageContext.Provider value={contextValue}>
      {children}
    </MessageContext.Provider>
  );
};

const useMessageContext = (): MessageContextProps => {
  const context = useContext(MessageContext);
  if (!context) {
    throw new Error('useMessageContext must be used within a MessageProvider');
  }
  return context;
};

export { MessageProvider, useMessageContext };
