export type colorsType = {
  corp: string;
  black: string;
  white: string;
  warning: string;
  info: string;
  brand: {
    dark: {
      '01': string;
      '02': string;
    };
    '01': string;
    '02': string;
    '03': string;
    '04': string;
  };
  grey: {
    '01': string;
    '02': string;

    '03': string;
    '04': string;
    '05': string;
    '06': string;
    disabled: string;
  };
  correct: {
    '01': string;
    light: string;
  };
  default: {
    '01': string;
  };
  simplifiable: {
    '01': string;

    light: string;
  };
  error: {
    '01': string;
    light: string;
  };
  selection: {
    '01': string;
    light: string;
  };
  success: {
    '01': string;
    light: string;
  };
  red: {
    dark: {
      '01': string;
    };
    '01': string;
    '02': string;
    '03': string;
    '04': string;
    '05': string;
  };
  blue: {
    dark: {
      '01': string;
    };
    '01': string;
    '02': string;
    '03': string;
    '04': string;
    '05': string;
  };
  green: {
    dark: {
      '01': string;
    };
    '01': string;
    '02': string;
    '03': string;
    '04': string;
    '05': string;
  };
  navy: {
    dark: {
      '01': string;
    };
    '01': string;
    '02': string;
    '03': string;
    '04': string;
    '05': string;
  };
  purple: {
    dark: {
      '01': string;
    };
    '01': string;
    '02': string;
    '03': string;
    '04': string;
    '05': string;
  };
  orange: {
    dark: {
      '01': string;
    };
    '01': string;
    '02': string;
    '03': string;
    '04': string;
    '05': string;
  };
  background: {
    '01': string;
  };
};

export const colors: colorsType = {
  corp: '#18c6b5',
  black: '#333',
  white: '#fff',
  warning: '#ED6C02',
  info: '#2196F3',

  brand: {
    dark: {
      '01': '#108478',
      '02': '#14A395',
    },
    '01': '#18C6B5',
    '02': '#8BE2DA',
    '03': '#D1F4F0',
    '04': '#E8F9F8',
  },

  grey: {
    '01': '#333333',
    '02': '#757575',
    '03': '#949494',
    '04': '#CCCCCC',
    '05': '#E5E5E5',
    '06': '#F5F5F5',
    disabled: 'rgba(255, 255, 255, 0.5)',
  },

  correct: {
    '01': '#84D65C',
    light: '#E8F9E8',
  },

  simplifiable: {
    '01': '#FFC033',
    light: '#FFF5E0',
  },

  error: {
    '01': '#FF5233',
    light: '#FFE5E0',
  },

  selection: {
    '01': '#4BD1EF',
    light: '#E3F8FD',
  },

  success: {
    '01': '#84D65C',
    light: '#E8F9E8',
  },
  default: {
    '01': '#fff',
  },

  red: {
    dark: {
      '01': '#CC1F00',
    },
    '01': '#FF5233',
    '02': '#FF5233',
    '03': '#FFA899',
    '04': '#FFD4CC',
    '05': '#FFE9E5',
  },

  blue: {
    dark: {
      '01': '#2392AB',
    },
    '01': '#57C3DB',
    '02': '#81D2E4',
    '03': '#ABE1ED',
    '04': '#D5F0F6',
    '05': '#D5F0F6',
  },

  green: {
    dark: {
      '01': '#51A329',
    },
    '01': '#84D65C',
    '02': '#A3E184',
    '03': '#C2EBAD',
    '04': '#E0F5D6',
    '05': '#F0FAEB',
  },

  navy: {
    dark: {
      '01': '#2365AB',
    },
    '01': '#5797DB',
    '02': '#81B1E4',
    '03': '#ABCBED',
    '04': '#D5E5F6',
    '05': '#EAF1FB',
  },

  purple: {
    dark: {
      '01': '#8B4185',
    },
    '01': '#AD52A6',
    '02': '#CE97CA',
    '03': '#DEBADC',
    '04': '#EFDCED',
    '05': '#F7EEF6',
  },

  orange: {
    dark: {
      '01': '#CC6600',
    },
    '01': '#FF9933',
    '02': '#FFB266',
    '03': '#FFCC99',
    '04': '#FFE5CC',
    '05': '#FFF2E5',
  },

  background: {
    '01': 'radial-gradient(180.67% 115.83% at 116.57% 101.3%,#ffe9d2 9.42%,#dbd1db 50.94%,#c0c0e2 86.3%)',
  },
};
