import { useMutation } from 'react-query';
import { Feedback } from '../utils/types';
import { apiErrorHandling } from '../utils/api-error-handling';
import { axiosInstance } from '../utils/axios-instance';

type ApiRequest = {
  body: Body;
  apiUrl: string;
};

type Body = {
  questionId: string;
  seed?: number;
  studentAnswers: string[] | string[][];
};

export const wirisValidateAnswers = async (
  request: ApiRequest,
): Promise<Feedback> => {
  const { body, apiUrl } = request;

  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: `${apiUrl}/algebra/verify`,
      data: body,
    });
    return {
      isCorrect: data.correct,
      solution: data.correctAnswers[0],
      slotsCorrectness: data.slotsCorrectness,
    };
  } catch (error) {
    apiErrorHandling(error, {
      callOrigin: 'wirisValidateAnswers',
      request: request,
    });
    throw error;
  }
};

export default function useValidateWirisAnswers() {
  const mutation = useMutation(wirisValidateAnswers, { retry: 3 });
  return mutation;
}
