import styled, { css } from 'styled-components';

import { Icons } from '../../icons';
import type { IconType } from '../../icons';

const Sizes = {
  S: 0.75,
  M: 1,
  L: 1.375,
  XL: 3.75,
  XXL: 4.5,
  //TODO: remove these sizes when unified
  EXIT_S: 1.5,
  EXIT_M: 2,
  EXIT_L: 2.5,
  EXIT_XL: 3,
} as const;
export type IconSize = keyof typeof Sizes;

type Props = {
  color?: string;
  icon: IconType;
  size?: IconSize;
} & React.SVGProps<SVGSVGElement>;

const IconContainer = styled.div<{
  iconSize?: string;
}>`
  border-radius: 50%;
  display: grid;
  place-items: center;

  ${({ iconSize }) =>
    css`
      width: ${iconSize};
      height: ${iconSize};
    `}
`;

export function Icon({ color, icon, size = 'L', ...props }: Props) {
  const SelectedIcon = Icons[icon];
  const iconViewBox = `0 0 20 20`;
  const iconSize = `${Sizes[size]}rem`;
  return (
    <IconContainer iconSize={iconSize}>
      <SelectedIcon viewBox={iconViewBox} color={color} {...props} />
    </IconContainer>
  );
}
