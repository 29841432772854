import styled, { css, keyframes } from 'styled-components';

const modalContentAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(1rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const ModalOverlay = styled.div`
  height: 100%;
  width: 100vw;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
`;

export const ModalContent = styled.div`
  --modal-max-width: 600px;
  --modal-padding: ${({ theme }) => theme.spaces.large};
  --title-font-size: ${({ theme }) => theme.typography.typeScale.semilarge};
  --content-font-size: ${({ theme }) => theme.typography.typeScale.semicaption};
  --button-min-width: 150px;
  --button-padding: ${({ theme }) => theme.spaces.normal};
  --button-font-size: ${({ theme }) => theme.typography.typeScale.semicaption};

  max-width: var(--modal-max-width);
  padding: var(--modal-padding);
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: ${({ theme }) => theme.borders.radius.md};
  color: inherit;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  z-index: 9999;
  animation: ${modalContentAnimation} 0.2s ease-in;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    --modal-padding: ${({ theme }) => theme.spaces.semilarge};
    --title-font-size: ${({ theme }) => theme.typography.typeScale.semicaption};
    --content-font-size: ${({ theme }) => theme.typography.typeScale.xmedium};
    --button-min-width: 120px;
    --button-padding: ${({ theme }) => theme.spaces.semismall};
    --button-font-size: ${({ theme }) => theme.typography.typeScale.xmedium};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    --modal-padding: ${({ theme }) => theme.spaces.medium};
    --title-font-size: ${({ theme }) => theme.typography.typeScale.normal};
    --content-font-size: ${({ theme }) => theme.typography.typeScale.small};
    --button-min-width: 100px;
    --button-padding: ${({ theme }) => theme.spaces.small};
    --button-font-size: ${({ theme }) => theme.typography.typeScale.small};
  }
`;

export const Title = styled.div`
  margin-bottom: ${({ theme }) => theme.spaces.xsmall};
  display: flex;
  justify-content: center;
  font-size: var(--title-font-size);
  font-weight: ${({ theme }) => theme.typography.weight.medium};
`;

export const ModalWrapper = styled.div`
  padding: ${({ theme }) => theme.spaces.medium};
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: var(--content-font-size);
`;

export const ButtonsContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spaces.semismall};
`;

export const StyledButton = styled.button<{ variant?: 'dark' }>`
  min-width: var(--button-min-width);
  margin-left: 10px;
  padding: var(--button-padding);
  color: ${({ theme }) => theme.colors.brand.dark['01']};
  background-color: transparent;
  border: 3px solid ${({ theme }) => theme.colors.brand.dark['01']};
  border-radius: ${({ theme }) => theme.borders.radius.md};
  font-size: var(--button-font-size);
  cursor: pointer;

  ${({ variant, theme }) =>
    variant === 'dark' &&
    css`
      color: ${theme.colors.white};
      background-color: ${theme.colors.brand.dark['01']};
    `}
`;
