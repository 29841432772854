export type typographyType = {
  regular: string;
  medium: string;
  rubik: string;
  rubikMedium: string;
  rubikSemibold: string;
  cmr10: string;
  typeScale: {
    big: string;
    xxlarge: string;
    xlarge: string;
    large: string;
    semilarge: string;
    caption: string;
    semicaption: string;
    medium: string;
    xmedium: string;
    normal: string;
    small: string;
    xsmall: string;
    xxsmall: string;
  };
  weight: {
    light: number;
    regular: number;
    medium: number;
    bold: number;
  };
  lineHeight: {
    xlarge: string;
    large: string;
    caption: string;
    medium: string;
    normal: string;
    small: string;
    xsmall: string;
    micro: string;
  };
};

export const typography: typographyType = {
  regular: '"Roboto", sans-serif',
  medium: '"Roboto Medium", sans-serif',
  rubik: `'Rubik', sans-serif`,
  rubikMedium: `'Rubik Medium', sans-serif`,
  rubikSemibold: `'Rubik Semibold', sans-serif`,
  cmr10: `'Cmr10', sans-serif`,

  typeScale: {
    big: '3rem', // 48px
    xxlarge: '2rem', // 32px
    xlarge: '1.875rem', // 30px
    large: '1.6875rem', // 27.5px
    semilarge: '1.625rem', // 26px
    caption: '1.5rem', // 24px
    semicaption: '1.312rem', // 21px
    medium: '1.25rem', // 20px
    xmedium: '1.125rem', // 18px
    normal: '1rem', // 16px
    small: '0.875rem', // 14px
    xsmall: '0.75rem', // 12px
    xxsmall: '0.625', // 10px
  },

  weight: {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
  },

  lineHeight: {
    xlarge: '3.5rem', //   56px
    large: '2rem', //      32px
    caption: '1.75rem', // 28px
    medium: '1.5', //      24px
    normal: '1.25rem', //  20px
    small: '1rem', //      16px
    xsmall: '0.875rem', // 14px
    micro: '0.75rem', //   12px
  },
};
