import React, { createContext, useState, useContext } from 'react';

interface NetworkContextProps {
  slowRequest: boolean;
  setSlowRequest: React.Dispatch<React.SetStateAction<boolean>>;
}

const NetworkContext = createContext<NetworkContextProps | undefined>(undefined);

const NetworkProvider: React.FC<React.PropsWithChildren<object>> = ({ children }) => {
  const [slowRequest, setSlowRequest] = useState(false);

  const contextValue: NetworkContextProps = {
    slowRequest,
    setSlowRequest,
  };

  return (
    <NetworkContext.Provider value={contextValue}>
      {children}
    </NetworkContext.Provider>
  );
};

const useNetworkContext = (): NetworkContextProps => {
  const context = useContext(NetworkContext);
  if (!context) {
    throw new Error('useNetworkContext must be used within a NetworkProvider');
  }
  return context;
};

export { NetworkProvider, useNetworkContext };
