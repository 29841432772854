//TODO: Only for ActivityProvider. Add a actionCode to make it scalable
const time = {
  start: 0,
  end: 0,
};

export const startTimer = () => {
  time.end = 0;
  time.start = Date.now();
};
export const stopTimer = () => {
  time.end = Date.now();
};
export const getElapsedTime = () => {
  if (!time.start) return 0;
  return Math.round((Date.now() - time.start) / 1000);
};
export const getTotalTime = () => {
  return Math.round((time.end - time.start) / 1000);
};
