import { useEffect, useState } from 'react';
import { LottieAnimation } from '../lottie-animation';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WifiAnimation = (): JSX.Element => {
  const [animationData, setAnimationData] = useState<any>(null);

  useEffect(() => {
    // Dynamically import the animation JSON from the given path
    import('./wifi-animation.json')
      .then((data) => {
        setAnimationData(data.default);
      })
      .catch((error) => console.error('Error loading animation data:', error));
  }, []);

  if (!animationData) return <></>;

  return (
    <Container>
      <LottieAnimation
        animationData={animationData}
        width={60}
        height={60}
        loop
        autoplay
      />
    </Container>
  );
};

export default WifiAnimation;
