import 'react-loading-skeleton/dist/skeleton.css';
import { useEffect } from 'react';
import { QueryClientProvider } from 'react-query';
import './config/i18n';
import { useTranslation } from 'react-i18next';
import {
  ActivityProvider,
  ConfigProvider,
  MessageProvider,
  Payload,
  useEventListener,
  useMessageContext,
  NetworkProvider,
} from '@web-contents/shared';
import { queryClient } from './config/query-client';
import { ThemeWrapper } from '@web-contents/ui';
import LazyRenderer from './lazy-renderer';
import 'katex/dist/katex.min.css';

type MessageProps = {
  children: React.ReactNode;
};

type AppProps = {
  payload: Payload;
};

const ChildrenWithMessage = ({ children }: MessageProps) => {
  const { updateMessageInput, messageOutput } = useMessageContext();

  useEventListener('messageUpdatedInput', (e: any) => {
    updateMessageInput(e.detail.message);
  });

  // useEffect to detect changes in message
  useEffect(() => {
    if (messageOutput) {
      // Create a custom event
      const messageEvent = new CustomEvent('messageUpdatedOutput', {
        detail: {
          messageOutput,
        },
      });
      // Dispatch the custom event
      window.dispatchEvent(messageEvent);
    }
  }, [messageOutput]);

  return <>{children}</>;
};

export function App(props: AppProps) {
  const { VITE_UNITY_STANDALONE_BUILD_DOMAIN } = import.meta.env;
  const { i18n } = useTranslation();
  const { payload } = props;
  const {
    userParameters: { language },
    solverUrl,
  } = payload;

  //TODO: create a util function to get the first language (REVE-2711)
  const uiLanguage = language?.split(',')[0];

  useEffect(() => {
    if (language !== null) {
      i18n.changeLanguage(uiLanguage);
      localStorage.setItem('language', language);
    }
  }, [i18n, language]);
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeWrapper>
        <NetworkProvider>
          <MessageProvider>
            <ChildrenWithMessage>
              <ConfigProvider
                unityBuildsUrl={VITE_UNITY_STANDALONE_BUILD_DOMAIN}
              >
                <ActivityProvider apiUrl={solverUrl} payload={payload}>
                  <LazyRenderer />
                </ActivityProvider>
              </ConfigProvider>
            </ChildrenWithMessage>
          </MessageProvider>
        </NetworkProvider>
      </ThemeWrapper>
    </QueryClientProvider>
  );
}

export default App;
