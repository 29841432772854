export type bordersType = {
  size: {
    '00': string;
    '01': string;
    '02': string;
    '03': string;
    '04': string;
  };
  radius: {
    xs: string;
    ms: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
    xxl: string;
  };
};

export const borders: bordersType = {
  size: {
    '00': '0px solid',
    '01': '1px solid',
    '02': '2px solid',
    '03': '3px solid',
    '04': '4px solid',
  },

  radius: {
    xs: '0rem',
    ms: '0.125rem',
    sm: '0.25rem',
    md: '0.5rem',
    lg: '1.25rem',
    xl: '1.875rem',
    xxl: '3.75rem',
  },
};
