import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

type ConfigProviderProps = {
  children: ReactNode;
  unityBuildsUrl: string;
};

const ConfigContext = createContext({
  unityBuildsUrl: '',
});

function ConfigProvider({ children, unityBuildsUrl }: ConfigProviderProps) {
  const [config, setConfig] = useState({ unityBuildsUrl });

  const value = useMemo(
    () => ({
      ...config,
      setConfig,
    }),
    [config]
  );

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
}

function useConfigContext() {
  const context = useContext(ConfigContext);

  if (context === undefined) {
    throw new Error('useConfigContext must be used within a ConfigProvider');
  }

  return context;
}

export { ConfigProvider, useConfigContext };
