import styled from 'styled-components';

export const StyledIconButton = styled.button<{ buttonSize: string }>`
  width: ${({ buttonSize }) => buttonSize};
  height: ${({ buttonSize }) => buttonSize};
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: ${({ theme }) => theme.borders.radius.md};
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
  transition: background-color 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey['06']};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.grey['06']};
    box-shadow: none;
  }
  &:disabled {
    color: ${({ theme }) => theme.colors.grey['03']};
    background: ${({ theme }) => theme.colors.grey.disabled};
  }
`;
