import { t } from 'i18next';
import styled, { css } from 'styled-components';

type Props = {
  text?: string;
  size?: 'S' | 'M' | 'L';
  variant?: 'regular' | 'stretch';
  onClick: () => void;
};

const StyledNextButton = styled.button<{
  size: 'S' | 'M' | 'L';
  variant: 'regular' | 'stretch';
}>`
  position: absolute;
  width: 204px;
  height: 86px;
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.24);
  border-radius: ${({ theme }) => theme.borders.radius.md};
  font-family: ${({ theme }) => theme.typography.regular};
  font-size: ${({ theme }) => theme.typography.typeScale.xxlarge};
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 1s ease-in;

  ${({ size }) => {
    if (size === 'M') {
      return css`
        width: 177px;
        height: 72px;
        font-size: ${({ theme }) => theme.typography.typeScale.large};
      `;
    }
    if (size === 'S') {
      return css`
        width: 170px;
        height: 57px;
        font-size: ${({ theme }) => theme.typography.typeScale.semicaption};
      `;
    }
  }}

  ${({ variant, size }) => {
    if (variant === 'stretch') {
      if (size === 'L') {
        return css`
          width: 120px;
          font-size: ${({ theme }) => theme.typography.typeScale.caption};
        `;
      }

      if (size === 'M') {
        return css`
          width: 100px;
          font-size: ${({ theme }) => theme.typography.typeScale.medium};
        `;
      }
      if (size === 'S') {
        return css`
          width: 80px;
          font-size: ${({ theme }) => theme.typography.typeScale.normal};
        `;
      }
    }
  }}
`;

export function NextButton({
  text = t('global.continue'),
  size = 'L',
  variant = 'regular',
  onClick,
}: Props) {
  return (
    <StyledNextButton
      className="nextButton"
      size={size}
      variant={variant}
      onClick={onClick}
    >
      {text}
    </StyledNextButton>
  );
}
