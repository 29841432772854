import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Spanish from '../locales/es.json';
import Catalan from '../locales/ca.json';
import Euskera from '../locales/eu.json';
import English from '../locales/en.json';
import Italian from '../locales/it.json';
import EnglishUS from '../locales/en-us.json';
import PortugueseBR from '../locales/pt-br.json';
import Valencian from '../locales/va-es.json';
import Latam from '../locales/es-la.json';

const resources = {
  es: { translations: { ...Spanish } },
  ca: { translations: { ...Catalan } },
  en: { translations: { ...English } },
  eu: { translations: { ...Euskera } },
  it_IT: {
    translations: { ...Italian },
  },
  en_US: {
    translations: { ...EnglishUS },
  },
  pt_BR: {
    translations: { ...PortugueseBR },
  },
  va: {
    translations: { ...Valencian },
  },
  es_LA: {
    translations: { ...Latam },
  },
};

i18n.use(initReactI18next).init({
  lng: 'es',
  resources,
  fallbackLng: (code) => {
    if (!code || code === '') return 'es';
    return code.includes('es_') ? 'es_LA' : code;
  },
  whitelist: Object.keys(resources),
  debug: false,

  // have a common namespace used around the full app
  ns: ['translations', 'routes'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys
  nsSeparator: '::', // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },

  react: {
    wait: true,
  },
});

export default i18n;
