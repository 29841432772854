import { SVGProps } from 'react';
export const ExitIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <path
      fill="currentColor"
      fillOpacity={0.5}
      d="M7.931 15.659v2.61c0 .953.745 1.731 1.659 1.731h8.751c.914 0 1.659-.778 1.659-1.732V1.732C20 .778 19.255 0 18.341 0H9.59c-.914 0-1.659.778-1.659 1.731v2.61c0 .477.385.879.842.879.456 0 .841-.402.841-.879V2.158c0-.25.192-.427.409-.427h7.91c.24 0 .408.201.408.427v15.659a.414.414 0 0 1-.409.426H10c-.24 0-.409-.2-.409-.426v-2.183c0-.477-.385-.879-.841-.879-.457.025-.818.402-.818.904Z"
    />
    <path
      fill="currentColor"
      fillOpacity={0.5}
      d="M.382 9.711c-.096.076-.168.151-.24.251a.885.885 0 0 0 .24 1.205l5 3.488c.145.1.29.15.458.15.456 0 .817-.401.841-.878v-2.183h7.5c.698 0 1.251-.577 1.251-1.305s-.553-1.305-1.25-1.305H6.68V6.951a.795.795 0 0 0-.144-.477.825.825 0 0 0-1.154-.25l-5 3.487Z"
    />
  </svg>
);
