import styled from 'styled-components';
import { Icon } from '../icon';

const Sizes = {
  S: 1.5,
  M: 2,
  L: 2.5,
  XL: 3,
} as const;
export type ButtonSizes = keyof typeof Sizes;
export { Sizes as ExitButtonSizes };

type Props = {
  isDisabled?: boolean;
  size?: ButtonSizes;
  onClick: () => void;
} & React.HTMLAttributes<HTMLButtonElement>;

export const StyledExitButton = styled.button<{ buttonSize: string }>`
  align-items: center;
  align-self: flex-start;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.grey['02']};
  display: flex;
  height: ${({ buttonSize }) => buttonSize};
  justify-content: center;
  width: ${({ buttonSize }) => buttonSize};
  cursor: pointer;

  &:active {
    color: ${({ theme }) => theme.colors.grey['01']};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.grey['03']};
  }
`;

export function ExitButton({ isDisabled, size = 'L', onClick }: Props) {
  const buttonSize = `${Sizes[size]}rem`;
  return (
    <StyledExitButton
      aria-label="Exit"
      buttonSize={buttonSize}
      disabled={isDisabled}
      onClick={onClick}
    >
      <Icon icon="ExitIcon" size={`EXIT_${size}`} />
    </StyledExitButton>
  );
}
