import { ThemeProvider } from 'styled-components';

import { GlobalStyle, theme } from './theme';
import { FontsStyle } from './fonts';

type Props = {
  children: React.ReactNode;
};

export function ThemeWrapper({ children }: Props) {
  return (
    <ThemeProvider theme={theme}>
      <FontsStyle />
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
}
