import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

const StyledSkeletonContainer = styled.div`
  width: ${({ theme }) => theme.sizes.xlarge};
  height: ${({ theme }) => theme.sizes.xlarge};
  display: block;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    width: 42px;
    height: 42px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: ${({ theme }) => theme.sizes.semilarge};
    height: ${({ theme }) => theme.sizes.semilarge};
  }
`;

export function ExitButtonSkeleton() {
  return (
    <StyledSkeletonContainer>
      <Skeleton width="100%" height="100%" />
    </StyledSkeletonContainer>
  );
}
