import styled, { css, keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    transform: translateY(-100%) translateX(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0) translateX(-50%);
    opacity: 1;
  }
`;

const opacityAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const AlertContainer = styled.div<{
  size: 'S' | 'M' | 'L';
  status: 'success' | 'error';
  showWithOpacity?: boolean;
}>`
  position: absolute;
  width: 264px;
  height: 40px;
  top: ${({ theme }) => theme.spaces.normal};
  left: 50%;
  transform: translateX(-50%);
  padding: ${({ theme }) => theme.spaces.xsmall};
  color: ${({ theme }) => theme.colors.grey['01']};
  background-color: ${({ status, theme }) => theme.colors[status]['light']};
  border-radius: ${({ theme }) => theme.borders.radius.md};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.typography.typeScale.xmedium};
  animation: ${fadeIn} 1s ease;
  z-index: 3000;
  ${({ showWithOpacity }) =>
    showWithOpacity &&
    css`
      animation: ${opacityAnimation} 0.8s ease-in-out;
    `}

  ${({ size }) => {
    if (size === 'M') {
      return css`
        width: 240px;
        height: 36px;
        font-size: ${({ theme }) => theme.typography.typeScale.normal};
      `;
    }
    if (size === 'S') {
      return css`
        width: 212px;
        height: 32px;
        font-size: ${({ theme }) => theme.typography.typeScale.small};
      `;
    }
  }}
`;

export const IconContainer = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spaces.xsmall};
`;

export const TextContainer = styled.div`
  text-align: center;
  flex-grow: 1;
`;
