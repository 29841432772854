import axios, { AxiosRequestConfig } from 'axios';

export const axiosInstance = axios.create();

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  timeoutHandle?: NodeJS.Timeout;
}

export const setAxiosInterceptors = (setSlowRequest: React.Dispatch<React.SetStateAction<boolean>>) => {

  axiosInstance.interceptors.request.use(
    async (config: CustomAxiosRequestConfig) => {
      const language = localStorage.getItem('language');
      if(!config.timeoutHandle) config.timeoutHandle = setTimeout(() => {
        setSlowRequest(true);
      }, 5000);
      return {
        ...config,
        headers: {
          ...config.headers,
          'Accept-Language': config.headers?.['Accept-Language'] || language,
        },
      } as any;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      const config = response.config as CustomAxiosRequestConfig;
      if (config.timeoutHandle) {
        setSlowRequest(false);
        clearTimeout(config.timeoutHandle);
      }
      return response;
    },
    (error) => {
      const config = error.config as CustomAxiosRequestConfig;
      if (config && config.timeoutHandle) {
        setSlowRequest(false);
        clearTimeout(config.timeoutHandle);
      }
      return Promise.reject(error);
    },
  );
};
