import { SVGProps } from 'react';
export const CheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <circle cx={10} cy={10} r={10} fill="currentColor" />
    <path
      fill="#fff"
      d="M14.482 6.26c-.28-.201-.636-.29-.988-.25-.352.04-.672.207-.89.465l-3.75 4.448L7.25 9.448a1.405 1.405 0 0 0-.935-.33 1.397 1.397 0 0 0-.922.36c-.245.225-.386.53-.392.848-.006.32.123.628.359.861l2.675 2.462c.253.227.592.353.945.351h.083c.19-.01.374-.058.542-.14.168-.08.315-.194.432-.332l4.682-5.539c.108-.128.188-.274.234-.43a1.138 1.138 0 0 0-.128-.936 1.268 1.268 0 0 0-.342-.363Z"
    />
  </svg>
);
