import { createGlobalStyle } from 'styled-components';
import { borders, bordersType } from './borders';
import { breakpoints, breakpointsType } from './breakpoints';
import { colors, colorsType } from './colors';
import { sizes, sizesType } from './sizes';
import { spaces, spacesType } from './spaces';
import { typography, typographyType } from './typography';
import { DefaultTheme } from 'styled-components';

export type StyleClosetTheme = DefaultTheme & {
  borders: bordersType;
  breakpoints: breakpointsType;
  colors: colorsType;
  htmlTag: typeof typography;
  sizes: sizesType;
  spaces: spacesType;
  typography: typographyType;
};

const theme: StyleClosetTheme = {
  borders,
  breakpoints,
  colors,
  htmlTag: typography,
  sizes,
  spaces,
  typography,
};

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  html, body {
    font-family: ${typography.regular};
    height: 100%;
    width: 100%;
  }
  #root-webcontents {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export { theme };
