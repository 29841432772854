import styled, { css } from 'styled-components';

const defaultColor = '#4c4c4c';

export const ToggleContainer = styled.div`
  position: relative;
`;

export const ToggleLabel = styled.label<{
  color?: string;
  size: 'S' | 'M' | 'L';
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 52px;
  height: 26px;
  border: ${({ theme, color }) =>
    `${theme.borders.size['01']} ${color || defaultColor}`};
  border-radius: 100px;
  cursor: pointer;

  ${({ size }) => {
    if (size === 'M') {
      return css`
        width: 44px;
        height: 24px;
      `;
    }
    if (size === 'S') {
      return css`
        width: 36px;
        height: 20px;
      `;
    }
  }}

  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin: ${({ theme }) => theme.spaces.micro};
    background: ${({ color }) => color || defaultColor};
    transition: 0.2s;

    ${({ size }) => {
      if (size === 'M') {
        return css`
          width: 18px;
          height: 18px;
        `;
      }
      if (size === 'S') {
        return css`
          width: 15px;
          height: 15px;
        `;
      }
    }}
  }
`;

export const ToggleInput = styled.input<{
  inputSize: 'S' | 'M' | 'L';
}>`
  width: 20px;
  height: 20px;
  opacity: 0;
  z-index: 1;
  border-radius: 100px;

  &:checked ~ ${ToggleLabel} {
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      margin-left: 26px;
      transition: 0.2s;
      ${({ inputSize }) => {
        if (inputSize === 'M') {
          return css`
            margin-left: 22px;
            width: 18px;
            height: 18px;
          `;
        }
        if (inputSize === 'S') {
          return css`
            margin-left: 17px;
            width: 15px;
            height: 15px;
          `;
        }
      }}
    }
  }
  &:disabled ~ ${ToggleLabel} {
    background: #bebebe;
    cursor: default;
  }
  &:checked:disabled ~ ${ToggleLabel} {
    background: #fff;
  }
`;
