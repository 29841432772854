import styled from 'styled-components';

export const BaseInputStyles = `
  margin-bottom: 15px;
  padding: 10px;
  font-size: 18px;
  width: 100%;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
`;

export const InterfaceSelect = styled.select`
  ${BaseInputStyles}
`;
