import { SVGProps } from 'react';
export const CrossIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <circle cx={10} cy={10} r={10} fill="currentColor" />
    <path
      fill="#fff"
      d="M14.167 14.166c.46-.46.46-1.206 0-1.666l-2.5-2.5 2.5-2.5A1.179 1.179 0 0 0 12.5 5.833l-2.5 2.5-2.5-2.5A1.179 1.179 0 0 0 5.833 7.5l2.5 2.5-2.5 2.5A1.178 1.178 0 1 0 7.5 14.167l2.5-2.5 2.5 2.5c.46.46 1.207.46 1.667 0Z"
    />
  </svg>
);
